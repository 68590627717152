@import "../../../styles/_chunk";

.MidPageBanner {
  background: darken(#360c4c, 15%);
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;
  padding-bottom: 33.333%;

  @include media-breakpoint-up(md) {
    min-height: 170px;
    padding-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    min-height: 190px;
  }

  @include media-breakpoint-up(xl) {
    min-height: 210px;
  }

  @include media-breakpoint-up(xxl) {
    min-height: 220px;
  }

  .MidPageBanner__contentContainer {
    align-items: center;
    width: 100%;

    img {
      object-fit: contain;
      width: 100%;
      z-index: 2;
      position: relative;

      @include media-breakpoint-up(md) {
        height: 170px;
      }

      @include media-breakpoint-up(lg) {
        height: 190px;
      }

      @include media-breakpoint-up(xl) {
        height: 210px;
      }

      @include media-breakpoint-up(xxl) {
        height: 220px;
      }
    }
  }

  .MidPageBanner__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.PreviewMidPageBanner__mobilePreviewContainer {
  .PreviewMidPageBanner__mobilePreview {
    width: 375px;
    border: 2px solid $white;

    @include theme(light) {
      border: 2px solid $black;
    }

    .MidPageBanner {
      padding-bottom: 33.33%;
      min-height: unset !important;
    }
  }
}

.PreviewMidPageBanner__desktopPreviewContainer {
  margin-top: 2rem;
}
